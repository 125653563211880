/* wrapper.module.css */

/* Definición global de variables CSS por defecto */
:root {
  /* Variables predeterminadas para Post One */
  --post-one-font-family: 'Nunito Sans', sans-serif;
  --post-one-font-weight: 800;
  --post-one-margin: 0;
  --post-one-text-shadow: 0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #001c30;
  --post-one-line-height: 1em;

  /* Variables predeterminadas para Post Two */
  --post-two-font-family: 'Orbitron', sans-serif;
  --post-two-margin: 0;
  --post-two-color: #001c30;
  --post-two-text-shadow: 0 0 5px #e0d9dd, 0 0 10px #d94bfc, 0 0 15px #fcf8f7, 0 0 20px #b1a6d2;
  --post-two-font-weight: 800;

    /* Variables predeterminadas para Post About */
    --post-about-background-color: rgba(0, 0, 0, 0.2);
    --post-about-color: #FFF;

  /* Variables globales para el tema */
  --background-color: #001c30;
  --text-color: #e0e0e0;
  --link-color: #66b2ff;
  --link-hover-color: #fff;

  /* Variables de tema específicas */
  --primary-color: #001c30;
  --secondary-color: #230043;
  --third-color: #330800;
  --fourth-color: #f0f0f0;

  /* Botones */
  --btn-bg-color: var(--primary-color);
  --btn-hover-color: var(--third-color);

  /* Sección About */
  --about-bg-color: #000;
  --about-text-color: #fff;
  --about-highlight: #a0c9ff;

    /* Sección Contact */
    --contact-text-color: #001c30;

  /* Footer */
  --footer-text-color: white;
  --footer-bg-color: #EF32D9;
  --footer-link-color: white;
  --footer-link-hover: #ddd;

  /* Menú y Header */
  --menu-category-color: #272727;
  --header-menu-title: #333;
  --header-menu-text: #666;
}

/* Estilos globales para el contenedor principal */
.wrapper {
  width: 100%;
  min-height: 100vh;
  /* Aquí se pueden agregar otros estilos globales; 
     por ejemplo, un padding o fondo que se personalice vía themeStyle */
}

/* Estilos para mensajes globales */
.infoMessage {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.errorMessage {
  background-color: #f2dede;
  color: #a94442;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}
