* {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  max-width: 100vw;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Abang';
  src: url('./assets/fonts/Abang.otf') format('opentype');
}

.container{
  max-width: 100%;
}



/*===============================
    Scroll Top
===============================*/
#scroll-Top .return-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  width: 40px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  background: orangered;
  border: 1px solid orangered;
  border-radius: 50%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  z-index: 2;
}

#scroll-Top .return-to-top:hover {
  background: #fff;
  color: orangered;
  border: 1px solid orangered;
}

#scroll-Top .return-to-top i {
  position: relative;
  bottom: 0;

}

#scroll-Top .return-to-top i {
  position: relative;
  animation-name: example;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}


/* Custom Scroll */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 111, 0);
}

::-webkit-scrollbar-thumb {
  background: rgb(240, 240, 240);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #686868;
}

.float-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  /* bottom: 90px; */
  right: 20px;
  /* background-color: #25d366;  */
  color: #FFF !important;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  margin-bottom: 10px;
  /* Espaciado entre botones */
}

.float-btn.whatsapp {
  background-color: #25d366;
}
.float-btn.whatsapp:hover {
  background-color: #83e965;
}

.float-btn.facebook {
  background-color: #3b5998;
}

.float-btn.instagram {
  background-color: #e4405f;
}

.float-btn.linkedin {
  background-color: #0077b5;
}

.float-btn.twitter {
  background-color: #1da1f2;
}

.float-btn.youtube {
  background-color: #ff0000;
}

.float-btn.tiktok {
  background: linear-gradient(135deg, #ee1d52, #69c9d0, #010101);
}

.float-btn.google {
  background-color: #4285f4;
}

.float-btn.pinterest {
  background-color: #e60023;
}

.float-btn.location-dot {
  background-color: #FF5722; /* Rojo-naranja vibrante */
  color: #FFF;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}
.float-btn.location-dot:hover {
  background-color: #E64A19; /* Color más oscuro para hover */
}


.my-float {
  margin-top: 16px;
}


.tittle-h3 {
  text-align: center;

}

@media (max-width: 768px) {
  .float-btn {
    bottom: 20px;
    /* Ajusta para pantallas pequeñas */
    right: 15px;
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
}


/* Animación de revelado */
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
